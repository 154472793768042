// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

global.jQuery, global.$ = require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ru';
import 'bootstrap-table';
import 'bootstrap-table/dist/locale/bootstrap-table-ru-RU';
import 'bootstrap-notify/bootstrap-notify.min';
import 'bootstrap-select';

var token = "7f6adc6ca6cb723242c771a50f7fde2a573b8292";

function suggest(query) {
  var serviceUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
  var request = {
    "query": query
  };
  var params = {
    type: "POST",
    contentType: "application/json",
    headers: {
      "Authorization": "Token " + token
    },
    data: JSON.stringify(request)
  }
  
  return $.ajax(serviceUrl, params);
}

function initDatepicker() {
  $('.datepicker').datepicker({
    autoclose: true,
    todayBtn: 'linked',
    clearBtn: true,
    format: 'dd.mm.yyyy',
    language: 'ru',
    zIndexOffset: 2000
  });

  $('.datepicker').on('keyup', function() {
    const date = $(this).val().replace(/\./g,'')
    if (date.length > 4) {
      $(this).val(date.slice(0,2) + '.' + date.slice(2,4) + '.' + date.slice(4))
    } else if (date.length > 2) {
      $(this).val(date.slice(0,2) + '.' + date.slice(2))
    }
  });
}

$(function(){
  initDatepicker();
  $('.select').selectpicker();

  $("#company_inn").on('change', function(e) {
    var promise = suggest(e.target.value);
    promise
      .done(function(response) {
        var company = response['suggestions'][0];
        var name = company['value'];
        var fullName = company['data']['name']['full_with_opf'];
        if (company['value'].indexOf('"') !== -1) {
          name = name.split('"').join('«').slice(0, -1) + '»';
          fullName = fullName.split('"').join('«').slice(0, -1) + '»';
        }
        $("#company_name").val(name);
        $("#company_full_name").val(fullName);
        $("#company_ogrn").val(company['data']['ogrn']);
        if (company['data']['address']) {
          $("#company_address").val(company['data']['address']['unrestricted_value']);
        }
        if (company['data']['type'] === 'INDIVIDUAL') {
          $("#company_manager").val(company['data']['name']['full']);
          $.notify({
            message: 'Введите полный адрес'
          }, 
          {
            type: 'warning',
            placement: {
              align: 'center',
            },
            offset: {
              x: 20,
              y: 400
            }
          });
        }
        else if (company['data']['management']) {
          $("#company_manager").val(company['data']['management']['name']);
        }
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
        console.log(errorThrown);
      });
  });

  $("#deal_responder_inn").on('change', function(e) {
    var promise = suggest(e.target.value);
    promise
      .done(function(response) {
        var company = response['suggestions'][0];
        var name = company['value'];
        var fullName = company['data']['name']['full_with_opf'];
        if (company['value'].indexOf('"') !== -1) {
          name = name.split('"').join('«').slice(0, -1) + '»';
          fullName = fullName.split('"').join('«').slice(0, -1) + '»';
        }
        $("#deal_responder_name").val(name);
        $("#deal_responder_full_name").val(fullName);
        $("#deal_responder_ogrn").val(company['data']['ogrn']);
        if (company['data']['address']) {
          $("#deal_responder_address").val(company['data']['address']['unrestricted_value']);
        }
        if (company['data']['type'] === 'INDIVIDUAL') {
          $("#deal_responder_manager").val(company['data']['name']['full']);
          $.notify({
            message: 'Введите полный адрес'
          }, 
          {
            type: 'warning',
            placement: {
              align: 'center',
            },
            offset: {
              x: 20,
              y: 400
            }
          });
        }
        else if (company['data']['management']) {
          $("#deal_responder_manager").val(company['data']['management']['name']);
        }
        if ($("#deal_name").val() == '')  {
          $("#deal_name").val(company['value']);
        }
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus);
        console.log(errorThrown);
      });
  });
});